<template>
  <b-row class="match-height">
    <b-col
lg="10"
offset="1"
> <b-card
    title="Add New Stock Opname"
  >
    <b-form
        @submit.prevent="submitNewStockOpname"
>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Date *"
            label-cols-md="12"
          >
          <b-form-datepicker
              v-model="stockOpnameDate"
/>
          </b-form-group>
        <b-form-group>
          <label for="no_invoice">Description *</label>
            <b-form-input
              id="description"
              v-model="stockOpnameDescription"
              placeholder="Description"
              required="required"
            />
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">Hour *</label>
            <b-form-input
              id="hour"
              v-model="stockOpnameHour"
              placeholder="Hour"
              required="required"
            />
        </b-form-group>
            <b-form-group
              label="Items *"
              label-for="chosenItem"
            >
            <Select2 v-model="chosenItem" :settings="settings" @select="putItem($event)" />
            </b-form-group>
            <b-row>
            <b-col
cols="4"
class="text-center font-weight-bold"
>
              Item name
            </b-col>
            <b-col
cols="3"
class="text-center font-weight-bold"
>
              Item QTY
            </b-col>
            <b-col
cols="3"
class="text-center font-weight-bold"
>
              Shelf
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              Actions
            </b-col>
            </b-row>
           <p />
          <b-row
v-for="(chosen) in chosens"
:key="chosen.value"
>
          <br>
            <b-col cols="4 text-center">
                <b-list-group>
                  <b-list-group-item href="#">
{{ chosen.label }}
</b-list-group-item>
                </b-list-group>
              </b-col>
            <b-col cols="3 text-center">
            <b-form-input
              v-model="chosen.qty"
              type="number"
              placeholder="Qty"
              required="required"
            />
            </b-col>
            <b-col cols="3" class="text-center">
              <v-select-new
                    v-model="chosen.shelf"
                    :options="shelfs"
                  />
            </b-col>
            <b-col cols="2 text-center">
          <b-button
            size="sm"
            type="button"
            variant="danger"
            class="mr-1"
            @click="removeRow(chosen.value)"
          >
                <feather-icon
                  icon="TrashIcon"
                  size="18"
                />
          </b-button>
            </b-col>
            <b-col cols="12">
              <br>
            </b-col>
            </b-row>
        </b-col>
        <!-- submit and reset -->
        <b-col offset-md="12">
          <b-button
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="submitButtonDisabled"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>

    </b-form>

  </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BListGroup, BListGroupItem, BFormDatepicker, BFormFile,
  } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

const VersionCheck = ''
const file = ''
const items = []
const chosenPR = ref()
const chosenItem = ref(3)
const chosens = []
const itemId = ''
const stockOpnameHour = ''
const stockOpnameDescription = ''
const stockOpnameDate = ''
const modalReferenceShow = false
const itemReference = ''
const itemLink = ''
const itemPrice = ''
const itemDescription = ''
const itemSupplier = ''
const contacts = []
const branches = []
const references = []
const currencies = []
const chosenBranch = ''
const transferTypes = ['IN', 'OUT']
const transferType = ''
const chosenContact = ''
const termOfPayment = ''
const billingAddress = ''
const deliveryAddress = ''
const itemCurrency = ''
const chosenShelf = ''
const shelfs = []
const modalReferenceHistoryShow = false
const submitButtonDisabled = false

export default {
  components: {
    BFormFile,
    BFormDatepicker,
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    const userToken = this.$cookies.get("userToken")
    return {
      settings: {
        ajax: {
          url: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ITEMS}`,
          beforeSend: xhr => {
            xhr.setRequestHeader('Authorization', `Bearer ${userToken}`)
          },
          data: params => {
            const query = {
              search: params.term,
              offset: 0,
              limit: 10,
              withCount: false,
            }

            // Query parameters will be ?search=[term]&type=public
            return query
          },
          processResults: response => {
            let resItems = response.data
            resItems = resItems.map(item => {
              item.text = `${item.item_code} - ${item.item_name}`
              item.id = item.item_id
              item.unitname = item.unit ? item.unit.unit_name : '-'
              return item
            })
            // Transforms the top-level key of the response object from 'items' to 'results'
            return {
              results: resItems,
            }
          },
        },
      },
      submitButtonDisabled,
      chosenItem,
      shelfs,
      chosenShelf,
      transferTypes,
      transferType,
      itemSupplier,
      stockOpnameHour,
      itemCurrency,
      currencies,
      deliveryAddress,
      chosenBranch,
      branches,
      termOfPayment,
      billingAddress,
      modalReferenceHistoryShow,
      chosenContact,
      contacts,
      itemId,
      stockOpnameDate,
      stockOpnameDescription,
      file,
      modalReferenceShow,
      itemReference,
      itemLink,
      itemPrice,
      itemDescription,
      references,
    VersionCheck,
    items,
    chosenPR,
    chosens,
    }
  },
  mounted() {
    const shelfsVariables = process.env.VUE_APP_SHELF_LIST.split(",")
    const shelfsRepeat = process.env.VUE_APP_SHELF_REPEAT.split(",")
    this.shelfs = []
    shelfsVariables.map(s => {
      shelfsRepeat.map(r => {
        this.shelfs.push(`${s}${r}`)
      })
    })
      this.getItems()
      this.chosens = []
  },
  methods: {
    getCurrencies() {
      const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
    axios
      .get(`${process.env.VUE_APP_API_GET_CURRENCIES}`, { headers })
      .then(response => {
        if (response.data.success === true) {
              const itemsJoin = []
              console.log(response.data.data)
              response.data.data.map(elem => {
                itemsJoin.push({ label: `${elem.currency_full_name} (${elem.currency_symbol})`, value: elem.currency_id })
              })
              this.currencies = itemsJoin
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get Currencies Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(e => {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get Branches Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
    getBranches() {
      const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_BRANCHES}`, { headers })
      .then(response => {
        if (response.data.success === true) {
              const itemsJoin = []
              response.data.data.map(elem => {
                itemsJoin.push({ label: `${elem.branch_name} (${elem.branch_code})`, value: elem.branch_id })
              })
              this.branches = itemsJoin
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get Branches Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(e => {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get Branches Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
    getContacts() {
      const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
    axios
      .get(`${process.env.VUE_APP_API_GET_CONTACTS}`, { headers })
      .then(response => {
        if (response.data.success === true) {
              const itemsJoin = []
              response.data.data.map(elem => {
                itemsJoin.push({ label: `${elem.contact_name} (${elem.contact_city})`, value: elem.contact_id })
              })
              this.contacts = itemsJoin
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get Contacts Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(e => {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get Contacts Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  getItems() {
    const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ITEMS}`, { headers })
      .then(response => {
        if (response.data.success === true) {
              const itemsJoin = response.data.data.map(elem => (
                { label: elem.item_name, value: elem.item_id }
              ))
              this.items = itemsJoin
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Data Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get Data Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(e => {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get Settings Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  submitNewStockOpname() {
    this.submitButtonDisabled = true
    const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
if (this.chosens.length > 0) {
  const hasEmptyShelf = chosens.some(item => item.shelf === "")
  if (hasEmptyShelf) {
      this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please fill all shelf",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
  } else {
      const body = {
        stock_opname_description: this.stockOpnameDescription,
        stock_opname_hour: this.stockOpnameHour,
        stock_opname_date: this.stockOpnameDate,
        stock_opname_items: JSON.stringify(this.chosens),
        time: moment(),
      }

      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_CREATE_STOCK_OPNAME}`
    axios
      .post(url, body, { headers })
      .then(response => {
        this.submitButtonDisabled = false
        if (response.data.success === true) {
          this.chosens = []
          this.transferType = ""
          this.stockOpnameDescription = ""
          this.chosenBranch = null
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Create Stock Opname Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.replace({ name: 'apps-stock-opname-list' })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'CreateStock Opname Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.submitButtonDisabled = false
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Create Stock Opname Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    } else {
      this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please select items",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
    }
  },
    getOptionLabel(option) {
      return (option && option.label) || ''
    },
    putItem({ id, text, unitname }) {
      if (this.chosens.length > 0) {
        const item = {
          label: text,
          value: id,
          qty: 1,
          id: this.chosens.length + 1,
          unitname,
          shelf: "",
        }
        this.chosens.push(item)
        // }
      } else {
        const item = {
          label: text,
          value: id,
          qty: 1,
          id: this.chosens.length,
          unitname,
          shelf: "",
        }

        this.chosens.push(item)
      }
    },
    applyItem(val) {
      console.log(val.data)
    },
    getOptionKey(option) {
      return (option && option.value) || ''
    },
    inputSearch(search) {
      console.log(search)
    },
    handleFilesUpload(e) {
        this.file = e.target.files || e.dataStockOpname.files
    },
    addReference(val) {
      this.modalReferenceShow = true
      this.itemId = val
    },
    showReference(val) {
      const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
      this.itemId = val
      this.modalReferenceHistoryShow = true
    axios
      .get(`${process.env.VUE_APP_API_GET_ITEM_LOOKUP}${val}`, { headers })
      .then(response => {
        if (response.data.success === true) {
              this.references = response.data.data
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get References Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(e => {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get References Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    dateFormat(value, format = 'MM/DD/YYYY') {
        return moment(String(value)).format(format)
    },
    choose(referenceId, itemIdRef, price) {
      const index = this.chosens.findIndex(x => x.value === itemIdRef)
      this.chosens[index].reference_id = referenceId
      this.chosens[index].price = price
      this.modalReferenceHistoryShow = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Price Chosen',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    removeRow(val) {
      this.chosens = this.chosens.filter(obj => (obj.value !== val))
    },
    saveNewReference() {
      const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
      this.modalReferenceShow = false
      const body = {
        item_reference_item_id: this.itemId,
        item_reference_link: this.itemLink,
        item_reference_price: this.itemPrice,
        item_reference_description: this.itemDescription,
        item_reference_currency_id: this.itemCurrency.value,
        item_reference_supplier: this.itemSupplier,
        time: moment(),
      }
    axios
      .post(`${process.env.VUE_APP_API_CREATE_ITEM_LOOKUP}`, body, { headers })
      .then(response => {
        if (response.data.success === true) {
          this.itemId = ""
          this.itemLink = ""
          this.itemPrice = ""
          this.itemDescription = ""
          this.itemCurrency = ""
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'New Reference Added',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Adding New Reference Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Adding New Reference Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      this.itemReference = ""
      this.itemLink = ""
      this.itemPrice = ""
      this.itemDescription = ""
      this.modalReferenceShow = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Data Saved',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
  },
}
</script>
